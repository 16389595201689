import React from 'react';
import './DidYouKnow.scss';
import { WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';

interface IDidYouKnowProps {
    heading: string;
    text: string;
}

export const DidYouKnow: React.FC<IDidYouKnowProps> = (props) => {
    return (
        <WidthContainer>
            <FadeIn>
            <div className="DidYouKnow">
                <div>
                    <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                    <img src='/images/cta-bubbles-left.svg' alt='Decorative bubbles illustration' />
                </div>
                <div>
                <p dangerouslySetInnerHTML={{ __html: props.text }}>
                    
                </p>
                </div>
            </div>
            </FadeIn>
        </WidthContainer>
    )
}