import React from 'react';
import { graphql } from 'gatsby';
import { FooterArt, SmallKeyArt, Layout, PageContent, Quiz, Seo, StickyContent, WidthContainer, Split, Vendors, NeedToKnow, OurCommitment, Menu, Hero } from '../components';
import { Ratings } from '../components/FishTales/Ratings/Ratings';
import { DidYouKnow } from '../components/Home/DidYouKnow/DidYouKnow';
import { Video } from '../components/Home/Video/Video';


export default ({ data }) => {
    const fields = data.wpPage.fishTalesFields;

    return (
        <Layout>
            <Seo title='Wave to Table' description="test" />
            <SmallKeyArt />
            <PageContent>
                    <Hero heading={fields.ftHeroHeading} text={fields.ftHeroText} />
                    <Video videoUrl={'https://www.youtube.com/embed/5GvZxnmqv7g?si=8eMBq2TEAADFvGwQ'} hideSideImages />
                    <WidthContainer>
                        

                        {/* <Split text={fields.ftSplitOneText} /> */}
                        {/* <Vendors heading={fields.ftVendorsHeading} textOne={fields.ftVendorsTextOne} vendors={fields.ftVendorsVendors} textTwo={fields.ftVendorsTextTwo} /> */}
                        </WidthContainer>
                        <NeedToKnow heading={fields.ftNtkHeading} text={fields.ftNtkText} needToKnow={fields.ftNtkNeedToKnow} />
                       <WidthContainer>
                         <Ratings 
                            heading={fields.ratingsHeading}
                            ratingOneImage={fields.ratingsRatingoneimage}
                            ratingOneButton={fields.ratingsRatingonebutton}
                            ratingTwoImage={fields.ratingsRatingtwoimage}
                            ratingTwoButton={fields.ratingsRatingtwobutton}
                            ratingThreeImage={fields.ratingsRatingthreeimage}
                            ratingThreeButton={fields.ratingsRatingthreebutton}

                         />
                         <DidYouKnow heading={fields.ftDykheading} text={fields.ftDyktext} />
                        <OurCommitment text={fields.ftOcText} />
                        <Menu menu={fields.ftMenuMenuItems} />
                    </WidthContainer>
            </PageContent>
            <FooterArt />
        </Layout>
    );
};

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            slug
            id
            fishTalesFields {
                ftDykheading
                ftDyktext
                ratingsHeading
                ratingsRatingoneimage {
                    altText
                    sourceUrl
                }
                ratingsRatingonebutton {
                    title
                    url
                    target
                }
                ratingsRatingtwoimage {
                    altText
                    sourceUrl
                }
                ratingsRatingtwobutton {
                    title
                    url
                    target
                }
                ratingsRatingthreeimage {
                    altText
                    sourceUrl
                }
                ratingsRatingthreebutton {
                    title
                    url
                    target
                }
                ftHeroHeading
                ftHeroText
                ftMenuMenuItems {
                  ftMenuDescription
                  ftMenuFlavor
                  ftMenuLocation
                  ftMenuTexture
                  ftMenuTitle
                  ftMenuBadge {
                    altText
                    sourceUrl
                  }
                  ftMenuImage {
                    altText
                    sourceUrl
                  }
                }
                ftNtkHeading
                ftNtkText
                ftNtkNeedToKnow {
                  ftNtkHeading
                  ftNtkText
                  ftNtkImage {
                    altText
                    sourceUrl
                  }
                }
                ftOcText
              }
        }
    }
`;
