import React from 'react';
import { WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './Video.scss';

interface IVideoProps {
    heading?: string;
    strapline?: String;
    videoUrl: string;
    hideSideImages?: boolean;
}

export const Video: React.FC<IVideoProps> = (props) => {
    return (
        <div className={`Video__Outer${props.hideSideImages ? ' fishTales' : ''}`}>
            {
                !props.hideSideImages && <img className="Video__FishSideLeft" src="/images/fish-sides.svg" alt="Decorative fish illustation" />
            }

            <WidthContainer>
                <div className="Video__Inner">
                    <FadeIn>
                        { props.heading && <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3> }
                        { props.strapline && <h4>{props.strapline}</h4> }
                    </FadeIn>
                    <FadeIn>
                        <div className="Video__Container">
                        <div className='embed-container'><iframe src={props.videoUrl} frameBorder='0' allowFullScreen></iframe></div>
                            {
                                !props.hideSideImages && <img className="Video__BubblesSideRight" src="/images/cta-bubbles-left.svg" alt="Decorative bubbles illustation" />
                            }
                        </div>
                    </FadeIn>
                </div>
            </WidthContainer>
        </div>
    );
}