import React from 'react';
import { Button } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './Ratings.scss';

interface IRatingsProps {
    heading: string;
    ratingOneImage: {
        altText: string;
        sourceUrl: string;
    }
    ratingOneButton: {
        target: string;
        title: string;
        url: string;
    }
    ratingTwoImage: {
        altText: string;
        sourceUrl: string;
    }
    ratingTwoButton: {
        target: string;
        title: string;
        url: string;
    }
    ratingThreeImage: {
        altText: string;
        sourceUrl: string;
    }
    ratingThreeButton: {
        target: string;
        title: string;
        url: string;
    }
}

export const Ratings: React.FC<IRatingsProps> = (props) => {
    return (
        <div className="Ratings">
            <FadeIn>
            <h3>{props.heading}</h3>
            <div className="Ratings__Items">
                <div className="Ratings__Item">
                    <img src={props.ratingOneImage.sourceUrl} alt={props.ratingOneImage.altText} />
                    <Button minWidth='0' as="link" href={props.ratingOneButton.url} target={props.ratingOneButton.target}>{props.ratingOneButton.title}</Button>
                </div>
                <div className="Ratings__Item">
                    <img src={props.ratingTwoImage.sourceUrl} alt={props.ratingTwoImage.altText} />
                    <Button minWidth='0' as="link" href={props.ratingTwoButton.url} target={props.ratingTwoButton.target}>{props.ratingTwoButton.title}</Button>
                </div>
                <div className="Ratings__Item">
                    <img src={props.ratingThreeImage.sourceUrl} alt={props.ratingThreeImage.altText} />
                    <Button minWidth='0' as="link" href={props.ratingThreeButton.url} target={props.ratingThreeButton.target}>{props.ratingThreeButton.title}</Button>
                </div>
            </div>
            </FadeIn>
        </div>
    )
}